import React from "react";
import styles from "./LoginLayout.module.scss";
import { Link } from "react-router-dom";
import Logos from "assets/logos";
import { useSelector } from "react-redux";

export const LoginLayout = ({ children }) => {
  const { brand_logo_url } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails,
  );

  return (
    <div className={styles.loginLayout}>
      <Link className="loginLayout__logo" to="/">
        <img
          className={styles.loginLayout__logo}
          src={brand_logo_url == null ? Logos.secondaryLogo : brand_logo_url}
          alt="enlumi merchant"
        />
      </Link>

      <div className={styles.loginLayout__content}>{children}</div>
    </div>
  );
};
