/* eslint-disable no-unused-vars */
import React from "react";
import "fontawesome";
import "assets/styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "layout";
import Meta from "./Meta";
import AnimatedRoute from "./AnimatedRoutes";
import {
  ToastMessage,
  ApiErrorHandler,
  BusinessDetails,
} from "components/secondaryComponents";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_ENV === "production")
      window.console.log = () => {};
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MainLayout>
          <ApiErrorHandler>
            <ToastMessage>
              <BusinessDetails>
                <Meta />
                <AnimatedRoute />
              </BusinessDetails>
            </ToastMessage>
          </ApiErrorHandler>
        </MainLayout>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
