import React from "react";
import useMainLayout from "./useMainLayout";
import styles from "./MainLayout.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MainLayout = ({ children }) => {
  const {
    RequestLoader,
    setRequestLoaderProgress,
    requestLoaderProgress,
    MetaHandler,
    setMetaDetails,
    metaDetails,
  } = useMainLayout();
  return (
    <RequestLoader.Provider
      value={{ setRequestLoaderProgress, requestLoaderProgress }}
    >
      <div className={styles.mainLayout}>
        {/* this loader shows a progress on top of the page and can be triggered from any child components */}
        <div className={styles["request-loader"]}>
          <div className={styles["request-loader__bar"]}>
            <div
              className={styles["request-loader__bar__progress"]}
              style={{ width: `${requestLoaderProgress}%` }}
            ></div>
          </div>
          {requestLoaderProgress !== 0 && (
            <div className={styles["request-loader__spinner"]}>
              <FontAwesomeIcon
                icon={["fas", "spinner"]}
                className="fa-spin me-3"
                style={{ fontSize: "17px", color: "#F7AD3C" }}
              />
            </div>
          )}
        </div>
        {/* main children component */}
        <MetaHandler.Provider value={{ setMetaDetails, metaDetails }}>
          {children}
        </MetaHandler.Provider>
      </div>
    </RequestLoader.Provider>
  );
};
