import React from "react";
import {
  BioForm,
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useProfile from "./useProfile";

const Profile = () => {
  const { data, isEdited, setIsEdited, updateBioInfo } = useProfile();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Profile Info"
      description="update your Profile informations"
    >
      <RequestLoaderTrigger>
        <motion.div initial={{ y: 50 }} animate={{ y: 0 }}>
          <Link to="/app/settings">
            <FontAwesomeIcon
              className="dark--text"
              icon="angle-left"
              style={{ fontSize: "22px" }}
            />
          </Link>
          <h4 className="pt-3">Profile</h4>

          <BioForm
            buttonLoadingText="Updating..."
            buttonText="Update Profile"
            data={data}
            type="update"
            onSuccessCall={updateBioInfo}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
          />
        </motion.div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default Profile;
