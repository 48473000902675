import React from "react";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { MetaHandler } from "context";

const Meta = () => {
  const { metaDetails } = useContext(MetaHandler);
  return (
    <Helmet>
      <title>{metaDetails.title}</title>
      <meta name="description" content={metaDetails.description} />
    </Helmet>
  );
};

export default Meta;
