import React from "react";
import {
  InputField,
  DateInput,
  BoxedRadioInput,
  PhoneNumberInputField,
  Button,
} from "components/primaryComponents";
import styles from "./BioForm.module.scss";
import BioValidation from "./validation";
import useBioForm from "./useBioForm";
import { Controller } from "react-hook-form";

export const BioForm = ({
  buttonText,
  buttonLoadingText,
  type,
  data,
  onSuccessCall,
  isEdited = true,
  setIsEdited = () => {},
}) => {
  const {
    isLoading,
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    gender,
    dob,
    setFirstName,
    setLastName,
    setMiddleName,
    setEmail,
    setPhoneNumber,
    setGender,
    setDob,
    triggerBioUpdate,
  } = useBioForm({ type, data, onSuccessCall });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = BioValidation(
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    dob,
    gender,
  );

  return (
    <form className={styles.bioData} onSubmit={handleSubmit(triggerBioUpdate)}>
      {/* first name */}
      <Controller
        name="firstName"
        control={control}
        defaultValue={firstName}
        render={({ field: { onChange } }) => (
          <InputField
            name="firstName"
            label="First Name"
            isRequired={true}
            placeholder="Enter your first name"
            classNames="white"
            errorMessage={errors.firstName}
            onKeyUp={() =>
              errors.firstName !== undefined && trigger("firstName")
            }
            onChange={({ target: { value } }) => {
              onChange(value);
              setFirstName(value);
              setIsEdited(true);
            }}
            register={register}
          />
        )}
      />
      {/* last name */}
      <Controller
        name="lastName"
        control={control}
        defaultValue={lastName}
        render={({ field: { onChange } }) => (
          <InputField
            name="lastName"
            label="Last Name"
            isRequired={true}
            placeholder="Enter your last name"
            classNames="white"
            errorMessage={errors.lastName}
            onKeyUp={() => errors.lastName !== undefined && trigger("lastName")}
            onChange={({ target: { value } }) => {
              onChange(value);
              setLastName(value);
              setIsEdited(true);
            }}
            register={register}
          />
        )}
      />
      {/* middle name */}
      <Controller
        name="middleName"
        control={control}
        defaultValue={middleName}
        render={({ field: { onChange } }) => (
          <InputField
            name="middleName"
            label="Middle Name"
            isRequired={false}
            placeholder="Enter your Middle Name"
            classNames="white"
            errorMessage={errors.middleName}
            onKeyUp={() =>
              errors.middleName !== undefined && trigger("middleName")
            }
            onChange={({ target: { value } }) => {
              onChange(value);
              setMiddleName(value);
              setIsEdited(true);
            }}
            register={register}
          />
        )}
      />
      {/* phone number */}
      <Controller
        name="phoneNumber"
        control={control}
        defaultValue={phoneNumber}
        render={({ field: { onChange } }) => (
          <PhoneNumberInputField
            label="Phone Number"
            isRequired={true}
            placeholder="Enter your customer's phone number"
            // classNames="white"
            name="phoneNumber"
            isDisabled={true}
            errorMessage={errors.phoneNumber}
            onKeyUp={() =>
              errors.phoneNumber !== undefined && trigger("phoneNumber")
            }
            onChange={({ target: { value } }) => {
              onChange(value);
              setPhoneNumber(value);
              setIsEdited(true);
            }}
            register={register}
          />
        )}
      />

      {/* email */}
      <Controller
        name="email"
        control={control}
        defaultValue={email}
        render={({ field: { onChange } }) => (
          <InputField
            label="Email"
            isRequired={true}
            type="email"
            placeholder="Enter your customer's email address"
            classNames="white"
            name="email"
            errorMessage={errors.email}
            onKeyUp={() => errors.email !== undefined && trigger("email")}
            onChange={({ target: { value } }) => {
              onChange(value);
              setEmail(value);
              setIsEdited(true);
            }}
            register={register}
          />
        )}
      />
      {/* gender */}
      <div className="d-flex mb-2" style={{ gap: "15px" }}>
        <BoxedRadioInput
          name="gender"
          value={"MALE"}
          id="MALE"
          isChecked={gender === "MALE"}
          setRadioValue={() => {
            setGender("MALE");
            setIsEdited(true);
          }}
        >
          <span>Male</span>
        </BoxedRadioInput>
        <BoxedRadioInput
          name="gender"
          value={"FEMALE"}
          id="FEMALE"
          isChecked={gender === "FEMALE"}
          setRadioValue={() => {
            setGender("FEMALE");
            setIsEdited(true);
          }}
        >
          <span>Female</span>
        </BoxedRadioInput>
      </div>
      {/* date of birth */}
      <Controller
        name="dob"
        control={control}
        defaultValue={dob}
        render={({ field: { onChange } }) => (
          <DateInput
            label="Date of birth"
            isRequired={true}
            placeholder="Enter your customer's date of birth"
            classNames="white"
            name="dob"
            errorMessage={errors.dob}
            onKeyUp={() => errors.dob !== undefined && trigger("dob")}
            onChange={({ target: { value } }) => {
              onChange(value);
              setDob(value);
              setIsEdited(true);
            }}
            value={dob}
            register={register}
          />
        )}
      />

      <Button
        classNames="btn btn--primary cover"
        isLoading={isLoading}
        isDisabled={isLoading || !isEdited}
        loadingText={buttonLoadingText}
      >
        {buttonText}
      </Button>
    </form>
  );
};
