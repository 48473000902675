import authHttpClient from "../axiosBase/authHandler";

export const loginUser = (data) => {
  const response = authHttpClient.post(
    "/loyalty/login/",
    { ...data, business_slug: undefined },
    {
      headers: {
        "x-business-slug": data.business_slug,
      },
    },
  );
  return response;
};

export const loginOtp = (data) => {
  const response = authHttpClient.post("/loyalty/login-otp/", data);
  return response;
};

export const getCampaignDetails = (data) => {
  const response = authHttpClient.get(
    "/loyalty/giftcard-center/campaign_details/",
    {
      headers: {
        "x-business-slug": data.business_slug,
      },
    },
  );
  return response;
};
