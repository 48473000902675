import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const loginSchema = yup
  .object({
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
  })
  .required();

const LoginValidation = (phoneNumber) => {
  return useForm({
    resolver: yupResolver(loginSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      phoneNumber,
    },
  });
};

export default LoginValidation;
