import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUrlQuerysection } from "helpers";

const useAppGuard = () => {
  const [grantAccess, setGrantAcces] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`)) {
      localStorage.setItem(
        "nextRoute",
        location.pathname + getUrlQuerysection(),
      );
      return navigate("/login");
    }
    setGrantAcces(true);
  }, []);
  return { grantAccess };
};

export default useAppGuard;
