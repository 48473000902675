import React, { useContext } from "react";
import {
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./GiftCardSuccess.module.scss";
import { Button } from "components/primaryComponents";
import icons from "assets/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContext } from "context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
// import { formatCurrency } from "helpers";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const GiftCardSuccess = () => {
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const param = useParams();
  const queries = useSearchParams()[0];
  const redemptionCode = queries.get("redemption_code");
  const amount = queries.get("amount");
  const { brand_name } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails,
  );

  useEffect(() => {
    if (!redemptionCode || !amount)
      return navigate(`/app/gift-cards/${param.id}`);
  }, []);

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Gift card purchase"
      description="Gift card purchase successful"
    >
      <RequestLoaderTrigger>
        <motion.div initial={{ y: 100 }} animate={{ y: 0 }}>
          <Link to="/app">
            <FontAwesomeIcon
              className="dark--text"
              icon="angle-left"
              style={{ fontSize: "22px" }}
            />
          </Link>
          <div className={styles.giftCardSuccess}>
            <div className="text-center">
              <motion.img
                width="120px"
                className="mx-auto"
                src={icons.successIcon}
                alt="success"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2 }}
              />
            </div>
            <div className="text-center mt-3">
              <h5>Purchase successful</h5>
              <p className="purple--text">
                Kindly Redeem your Gift card with {brand_name}
              </p>

              {/* <div className="mt-5">
                <h4 className="mb-2 gray--text font-weight-normal">
                  Gift Card Value
                </h4>
                <h3 className="success--text">{formatCurrency(amount)}</h3>
              </div> */}
            </div>

            <div className="mt-auto">
              <div className={styles.giftCardCode}>{redemptionCode}</div>
              <CopyToClipboard
                onCopy={() =>
                  triggerToast(`Copied ${redemptionCode}`, "success")
                }
                text={redemptionCode}
              >
                <Button classNames="btn btn--primary cover">
                  Copy Giftcard code
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </motion.div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default GiftCardSuccess;
