import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const LoaderHelper = ({
  children,
  isLoading,
  classNames,
  fontSize = "20px",
  alignItem = "text-center",
}) => {
  return (
    <>
      {isLoading ? (
        <div className={`${alignItem} ${classNames}`}>
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            className="fa-spin purple--text"
            style={{ fontSize: fontSize }}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};
