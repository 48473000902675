import Dashboard from "./Dashboard";
import GiftCards from "./Dashboard/GiftCards";
import History from "./Dashboard/History";
import Settings from "./Settings";
import Profile from "./Profile";
import Earnings from "./Dashboard/History/Earnings";
import Purchases from "./Dashboard/History/Purchases";
import GiftCardDetails from "./GiftCardDetails";
import Rewards from "./Rewards";
import GiftCardSuccess from "./GiftCardSuccess";

const appPages = {
  Dashboard,
  History,
  GiftCards,
  Settings,
  Profile,
  Earnings,
  Purchases,
  GiftCardDetails,
  Rewards,
  GiftCardSuccess,
};

export default appPages;
