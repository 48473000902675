import httpClient from "axiosBase/axiosHandler";

export const getGiftCards = () => {
  const response = httpClient.get("loyalty/giftcard-center/");
  return response;
};

export const getGiftCardsDetails = (id) => {
  const response = httpClient.get(`loyalty/giftcard-center/${id}/`);
  return response;
};

export const purchaseGiftCard = (data) => {
  const response = httpClient.post(
    `loyalty/giftcard-center/${data.id}/purchase-giftcard/`,
    {},
  );
  return response;
};

export const getHistoryEarning = (page = 1) => {
  const response = httpClient.get(
    `loyalty/points-earned/?page=${page}&per_page=20`,
  );
  return response;
};

export const getHistoryPurchases = (page = 1, status) => {
  const response = httpClient.get(
    `loyalty/giftcard-center/purchased-giftcard/?page=${page}&per_page=20${
      status !== undefined ? "&status=" + status : ""
    }`,
  );
  return response;
};
