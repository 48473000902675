import React from "react";
import {
  BioForm,
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { LoginLayout } from "layout";
import { motion } from "framer-motion";
import useCustomerBioData from "./useCustomerBioData";

const CustomerBioData = () => {
  const { data, createBio } = useCustomerBioData();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Bio Data"
      description="Enter your Bio informations"
    >
      <RequestLoaderTrigger>
        <LoginLayout>
          <motion.div
            initial={{ marginTop: "50px" }}
            animate={{
              marginTop: "-40px",
              transition: { duration: 0.3, delay: 0.1 },
            }}
          >
            <h4>Add Customer</h4>
            <p className="mt-1 mb-4">
              Provide the following details about your customer.
            </p>

            <BioForm
              buttonLoadingText="Creating..."
              buttonText="Create Bio"
              data={data}
              type="create"
              onSuccessCall={createBio}
            />
          </motion.div>
        </LoginLayout>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default CustomerBioData;
