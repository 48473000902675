import React from "react";
import "./InputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

export const InputField = ({
  placeholder,
  type = "text",
  name = "",
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  floatError = true,
  ...props
}) => {
  return (
    <div className="input-field" htmlFor={name}>
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}

      <input
        data-testid="input"
        className={`${classNames} ${
          errorMessage !== undefined && "error-border"
        }`}
        type={type}
        name={name}
        placeholder={placeholder}
        id={name}
        {...register(name, { required: isRequired })}
        onChange={(e) => {
          onChange(e);
        }}
        disabled={isDisabled}
        onKeyUp={onKeyUp}
        defaultValue={value}
        {...props}
      />
      {(floatError || errorMessage !== undefined) && (
        <p
          className={`error-message text-danger ${
            errorMessage !== undefined ? "show" : ""
          }`}
          data-testid="error-message"
        >
          {errorMessage !== undefined && (
            <motion.span initial={{ x: -10 }} animate={{ x: 0 }}>
              <FontAwesomeIcon
                icon={["fas", "exclamation-circle"]}
                className="mr-2"
                style={{ fontSize: "13px" }}
              />
              {errorMessage !== undefined && (
                <span>{errorMessage.message}</span>
              )}
            </motion.span>
          )}
        </p>
      )}
    </div>
  );
};
