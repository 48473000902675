import React from "react";
import {
  RequestLoaderTrigger,
  MetaSetter,
} from "components/secondaryComponents";
import { OtpInputField } from "components/primaryComponents";
import { LoginLayout } from "layout";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import useLoginVerification from "./useLoginVerification";

const LoginVerification = () => {
  const { handleVerification, otp, isVerifing } = useLoginVerification();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Log In - Verification"
      description="Verify your account"
    >
      <RequestLoaderTrigger>
        <LoginLayout>
          <motion.div
            initial={{ y: "10vw" }}
            animate={{
              y: 0,
              transition: { duration: 0.3, delay: 0.1 },
            }}
          >
            <h4>Enter Verification Code</h4>
            <p className="mt-1 mb-4">
              Please enter the 4-digit OTP sent to your email or mobile number
              to log in.
            </p>

            <form style={{ width: "80%", maxWidth: "270px" }}>
              {!isVerifing ? (
                <OtpInputField
                  onChange={handleVerification}
                  numOfInputs={4}
                  value={otp}
                />
              ) : (
                <h4 className="purple--text font-weight-normal">
                  Verifying...
                </h4>
              )}
            </form>
            <Link to="/login" className="purple--text mt-3 d-block">
              Return to Login
            </Link>
          </motion.div>
        </LoginLayout>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default LoginVerification;
