import { getGiftCards } from "endpoints/giftCards";
import { useQuery } from "react-query";
import { ToastContext } from "context";
import { useContext } from "react";
import { useSelector } from "react-redux";

const useGiftCards = () => {
  const triggerToast = useContext(ToastContext);
  const { brand_logo_url } = useSelector(
    (state) => state.businessDetailsreducer.businessDetails,
  );
  const { data, isLoading } = useQuery(
    ["getGiftCards"],
    () => getGiftCards().then((res) => res?.data?.results),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    },
  );

  return {
    data: data || [],
    isLoading,
    brand_logo_url,
  };
};
export default useGiftCards;
