import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  businessDetails: {
    brand_name: null,
    brand_category: null,
    brand_url: null,
    brand_logo_url: null,
    description: null,
    term_and_condition_url: null,
    business: { business_phone_code: "234", country: "Nigeria" },
  },
};

export const businessDetailsSlice = createSlice({
  name: "businessInfo",
  initialState,
  reducers: {
    setBusinessDetails: (state, action) => {
      state.businessDetails = action.payload;
    },
  },
});

export const { setBusinessDetails } = businessDetailsSlice.actions;
