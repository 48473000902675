export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included
};

export const getUrlQuerysection = () => {
  let queries = window.location.search;
  return queries;
};

export const logoutHandler = () => {
  // handles logout
  localStorage.removeItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`);
  window.location.replace(`${window.location.origin}/login`);
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

export const getCustomerLoyaltyId = (token) => {
  const values = parseJwt(token);
  return values?.customer_id;
};

// this format numbers into kilo, Million, Billion ...
export const nFormatter = (num, digits = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatPoints = (val) => {
  return; //`${nFormatter(val)}${val > 0 ? " Balance" : " Balance"}`;
};

export const formatMoney = (val) => {
  return `NGN ${nFormatter(val)}`;
};

export const getSlugName = () => {
  if (process.env.REACT_APP_ENV === "production") {
    let host = window.location.host;
    host = host.split(".");
    if (host.length === 3) return host[0];
    return null;
  }

  return process.env.REACT_APP_DEFAULT_SLUG;
};

export const get422errorMessage = (errors) => {
  for (const key in errors) {
    if (typeof errors[key]?.[0] !== "string") {
      return get422errorMessage(errors?.[key]);
    } else
      return `${key?.replace("_", " ")?.toLocaleUpperCase()}: ${
        errors?.[key]?.[0]
      }`;
  }
};

// this function removes null, undefined and empty string values from an object
export function cleanObject(obj, keys = []) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  // delete key name specified
  keys?.forEach((key) => {
    delete obj[key];
  });
  return obj;
}

export const formatCurrency = (
  currencyParams = { decimal: 2, currencyCode: "NGN" },
) => {
  if (isNaN(currencyParams?.value)) return "0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyParams?.currencyCode || "NGN",
    minimumFractionDigits: currencyParams?.decimal || 2,
    currencyDisplay: "symbol",
  }).format(currencyParams?.value);
};
