import React from "react";
import {
  LoaderHelper,
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./Rewards.module.scss";
import { Button } from "components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import useRewards from "./useRewards";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatPoints } from "helpers";
import icons from "assets/icons";

const Rewards = () => {
  const { isLoading, data, triggerToast } = useRewards();

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | View Rewards"
      description="View Rewards"
    >
      <RequestLoaderTrigger>
        <motion.div initial={{ y: 200 }} animate={{ y: 0 }}>
          <Link to="/app">
            <FontAwesomeIcon
              className="dark--text"
              icon="angle-left"
              style={{ fontSize: "22px" }}
            />
          </Link>
          <Button classNames="gray--text btn btn--outline cover mt-3 mb-2">
            Unclaimed Rewards
          </Button>

          <LoaderHelper isLoading={isLoading} classNames="mt-5">
            <div className={styles.rewards__items}>
              {data?.pages[0].data?.length > 0 ? (
                <>
                  {data?.pages.map((page) =>
                    page?.data.map((item, index) => (
                      <div className={styles.rewardItem} key={index}>
                        <div className={styles.rowItem}>
                          <p className="smallest-text-size">Purchased on</p>
                          <p className="small-text-size dark--text">
                            {new Date(item?.purchased_on).toLocaleString(
                              "en-us",
                            )}
                          </p>
                        </div>
                        <div className={styles.rowItem}>
                          <p className="dark--text font-weight-semibold">
                            {item?.giftcard_name}
                          </p>
                          <p className={styles.points}>
                            -{formatPoints(item?.amount_in_points)}
                          </p>
                        </div>
                        <div className={styles.rowItem}>
                          <p className="smallest-text-size">Expires</p>
                          <p className="error--text small-text-size">
                            {new Date(item?.expires_at).toLocaleString("en-us")}
                          </p>
                        </div>
                        <div className={styles.codeSection}>
                          <p className="purple--text">
                            {item?.redemption_code}
                          </p>
                          <CopyToClipboard
                            onCopy={() =>
                              triggerToast(
                                `Copied ${item?.redemption_code}`,
                                "success",
                              )
                            }
                            text={item?.redemption_code}
                          >
                            <Button
                              classNames={`btn btn--outline ${styles.btn}`}
                            >
                              <FontAwesomeIcon icon="copy" className="mr-1" />
                              Copy
                            </Button>
                          </CopyToClipboard>
                        </div>
                        <div className={styles.rowItem}>
                          <p className="small-text-size dark--text">Status</p>
                          <p
                            className={`${styles.status} ${
                              item?.status === "UNUSED" ? styles.redeemed : ""
                            }`}
                          >
                            {item?.status === "USED" ? "Redeemed" : "Purchased"}
                          </p>
                        </div>
                      </div>
                    )),
                  )}
                </>
              ) : (
                <div className="text-center mt-5">
                  <img
                    width="211px"
                    src={icons.giftCardEmpty}
                    alt="emptyState"
                  />
                  <h5>No Purchases Available yet</h5>
                </div>
              )}
            </div>
          </LoaderHelper>
        </motion.div>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default Rewards;
