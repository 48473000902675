import React, { useContext, useEffect, useState } from "react";
import { getCampaignDetails } from "endpoints/auth";
import { useDispatch } from "react-redux";
import { setBusinessDetails } from "store/modules/businessInfo";
import { RequestLoader } from "context";
import { getRandomIntInclusive, getSlugName } from "helpers";
import { setApiErrorMessage } from "store/modules/apiErrorHandler";
import { useNavigate } from "react-router-dom";

export const BusinessDetails = ({ children }) => {
  const [isLoading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCampaign();
  }, []);

  const fetchCampaign = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    getCampaignDetails({ business_slug: getSlugName() })
      .then((response) => {
        dispatch(setBusinessDetails(response?.data?.data));
        setRequestLoaderProgress(100);
        setIsloading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsloading(false);
        if (error?.response?.status === 400) {
          dispatch(setApiErrorMessage(error?.response?.data?.detail));
          navigate("/not-found");
        }
      });
  };

  return <>{isLoading ? <p>Loading...</p> : <>{children}</>}</>;
};
