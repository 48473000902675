import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useCustomerBioData = () => {
  const params = useSearchParams()[0];
  const token = params.get("token");
  const accessNumber = params.get("access_number");
  const [firstName] = useState("");
  const [lastName] = useState("");
  const [middleName] = useState("");
  const [email] = useState("");
  const [phoneNumber] = useState(accessNumber ? accessNumber : "");
  const [gender] = useState("MALE");
  const [dob] = useState("");
  const data = {
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    gender,
    dob,
    businessName: "BashirMusa Enterprise",
    token,
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || !accessNumber) return navigate("/login");
  }, []);

  const createBio = () => {
    localStorage.setItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`, token);
    navigate("/app");
  };

  return {
    data,
    createBio,
  };
};
export default useCustomerBioData;
