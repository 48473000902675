import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  profileDetails: {
    first_name: null,
    last_name: null,
    middle_name: null,
    email: null,
    phone: null,
    gender: null,
    dob: null,
    point_claimed: null,
    loyalty_wallet: {
      balance: 0,
      balance_naira_equiv: "0.00",
    },
  },
};

export const profileDetailsSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
  },
});

export const { setProfileDetails } = profileDetailsSlice.actions;
