import React from "react";
import "./OtpInputField.scss";
import OtpInput from "react-otp-input";

export const OtpInputField = ({ name, value, numOfInputs = 4, onChange }) => {
  return (
    <div>
      <OtpInput
        className="otp-input__field"
        value={value}
        name={name}
        numInputs={numOfInputs}
        isInputNum={true}
        shouldAutoFocus={true}
        inputMode="numeric"
        onChange={onChange}
      />
    </div>
  );
};
