import { setApiErrorMessage } from "../../../store/modules/apiErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useApiErrorHandler = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.apiErrorReducer);

  useEffect(() => {
    if (message === null) return;

    setTimeout(() => {
      closeApiErrorHandler();
    }, 7000);
  }, [message]);

  const closeApiErrorHandler = () => {
    dispatch(setApiErrorMessage(null));
  };

  return { message, closeApiErrorHandler };
};
export default useApiErrorHandler;
