import Login from "./Login";
import LoginVerification from "./LoginVerification";
import CustomerBioData from "./CustomerBioData";

const authPages = {
  Login,
  LoginVerification,
  CustomerBioData,
};
export default authPages;
