import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useOnboardingGuard = () => {
  const [grantAccess, setGrantAcces] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`))
      navigate("/app");
    setGrantAcces(true);
  }, []);
  return { grantAccess };
};

export default useOnboardingGuard;
