// import all icons and export them as an object
import getlumiIcon from "./getlumi-icon.svg"; // sample code
import gearIcon from "./gear.svg";
import kongaIcon from "./kongaIcon.svg";
import arikIcon from "./arikIcon.svg";
import giftIcon from "./gift.svg";
import successIcon from "./success-mark.svg";
import giftCardEmpty from "./GiftCardEmpty.svg";

const icons = {
  getlumiIcon,
  gearIcon,
  giftIcon,
  kongaIcon,
  arikIcon,
  successIcon,
  giftCardEmpty,
};

export default icons;
