import React from "react";
import {
  MetaSetter,
  RequestLoaderTrigger,
} from "components/secondaryComponents";
import { Button, PhoneNumberInputField } from "components/primaryComponents";
import { LoginLayout } from "layout";
import { motion } from "framer-motion";
import LoginValidation from "./validation";
import { Controller } from "react-hook-form";
import useLogin from "./useLogin";

const Login = () => {
  const {
    phoneNumber,
    isLoading,
    brand_name,
    description,
    setPhoneCode,
    setPhoneNumber,
    handleLogin,
  } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = LoginValidation(phoneNumber);

  return (
    <MetaSetter
      title="Lumi Merchant Loyalty | Log In"
      description="Log in to your Lumi Merchant Loyalty account"
    >
      <RequestLoaderTrigger>
        <LoginLayout>
          <motion.form
            initial={{ y: "10vw" }}
            animate={{ y: 0 }}
            onSubmit={handleSubmit(handleLogin)}
          >
            <h4 style={{ textTransform: "capitalize" }}>
              Welcome to {brand_name} Loyalty Portal!
            </h4>
            <p className="mt-1 mb-4">{description}</p>

            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={phoneNumber}
              render={({ field: { onChange } }) => (
                <PhoneNumberInputField
                  label="Phone Number"
                  placeholder="Enter phone number"
                  isRequired={true}
                  name="phoneNumber"
                  classNames="white"
                  errorMessage={errors.phoneNumber}
                  onKeyUp={() =>
                    errors.phoneNumber !== undefined && trigger("phoneNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setPhoneNumber(value);
                  }}
                  onChangePhoneCode={(val) => setPhoneCode(val)}
                  register={register}
                  phoneCodeEditable={true}
                />
              )}
            />

            <Button
              classNames="btn btn--primary cover mt-4"
              isDisabled={isLoading}
              isLoading={isLoading}
              loadingText="Logging..."
            >
              Continue
            </Button>
          </motion.form>
        </LoginLayout>
      </RequestLoaderTrigger>
    </MetaSetter>
  );
};
export default Login;
