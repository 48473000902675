import { useContext, useState } from "react";
import { RequestLoader, ToastContext } from "context";
import { useSelector } from "react-redux";

const useProfile = () => {
  const {
    phone,
    first_name,
    last_name,
    middle_name,
    email: userEmail,
    gender: userGender,
    date_of_birth,
  } = useSelector((state) => state.profileDetailsReducer.profileDetails);
  const [firstName] = useState(first_name !== null ? first_name : "");
  const [lastName] = useState(last_name !== null ? last_name : "");
  const [middleName] = useState(middle_name !== null ? middle_name : "");
  const [email] = useState(userEmail !== null ? userEmail : "");
  const [phoneNumber] = useState(phone !== null ? phone : "");
  const [gender] = useState(userGender !== null ? userGender : "");
  const [dob] = useState(date_of_birth !== null ? date_of_birth : "");
  const triggerToast = useContext(ToastContext);
  const [isEdited, setIsEdited] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const data = {
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    gender,
    dob,
    businessName: "BashirMusa Enterprise",
    token: localStorage.getItem(`${process.env.REACT_APP_ACCESS_TOKEN_NAME}`),
  };

  const updateBioInfo = () => {
    setRequestLoaderProgress(100);
    triggerToast("Profile Updated Succesfully", "success");
    setIsEdited(false);
  };

  return {
    data,
    isEdited,
    setIsEdited,
    updateBioInfo,
  };
};
export default useProfile;
