import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// page imports
import authPages from "pages/authPages";
import appPages from "pages/appPages";
import { AppLayout } from "layout";
import { OnboardingGuard, AppGuard } from "components/secondaryComponents";

const AnimatedRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      {/* auth routes */}
      <Route
        path="/login"
        element={
          <OnboardingGuard>
            <authPages.Login />
          </OnboardingGuard>
        }
      />
      <Route
        path="/login/otp"
        element={
          <OnboardingGuard>
            <authPages.LoginVerification />
          </OnboardingGuard>
        }
      />
      <Route
        path="/customer-bio"
        element={
          <OnboardingGuard>
            <authPages.CustomerBioData />
          </OnboardingGuard>
        }
      />

      {/* app routes */}
      <Route
        path="/app"
        element={
          <AppGuard>
            <AppLayout />
          </AppGuard>
        }
      >
        <Route
          path=""
          element={
            <AppGuard>
              <appPages.Dashboard />
            </AppGuard>
          }
        >
          <Route index element={<Navigate to="/app/gift-cards" replace />} />
          <Route
            path="gift-cards"
            element={
              <AppGuard>
                <appPages.GiftCards />
              </AppGuard>
            }
          />
          <Route
            path="history"
            element={
              <AppGuard>
                <appPages.History />
              </AppGuard>
            }
          >
            <Route
              index
              element={<Navigate to="/app/history/earnings" replace />}
            />
            <Route
              path="earnings"
              element={
                <AppGuard>
                  <appPages.Earnings />
                </AppGuard>
              }
            />
            <Route
              path="purchases"
              element={
                <AppGuard>
                  <appPages.Purchases />
                </AppGuard>
              }
            />
          </Route>
        </Route>
        <Route
          path="settings"
          element={
            <AppGuard>
              <appPages.Settings />
            </AppGuard>
          }
        />
        <Route
          path="profile"
          element={
            <AppGuard>
              <appPages.Profile />
            </AppGuard>
          }
        />
        <Route
          path="gift-cards/:id"
          element={
            <AppGuard>
              <appPages.GiftCardDetails />
            </AppGuard>
          }
        />
        <Route
          path="gift-cards/:id/success"
          element={
            <AppGuard>
              <appPages.GiftCardSuccess />
            </AppGuard>
          }
        />
        <Route
          path="rewards"
          element={
            <AppGuard>
              <appPages.Rewards />
            </AppGuard>
          }
        />
      </Route>

      {/* page not found route */}
      <Route
        path="/not-found"
        element={
          <h2 className="text-center mt-2 px-3">
            The resource you are looking for does not exist
          </h2>
        }
      />
      <Route path="*" element={<h1>404 page</h1>} />
    </Routes>
  );
};
export default AnimatedRoute;
