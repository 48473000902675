import { useState } from "react";
import { ToastContext } from "context";

const ToastMessageLogic = () => {
  const [toastMessageDetails, setToastMessageDetails] = useState(null);

  const triggerToast = (message, type) => {
    clearTimeout(window.toastTimeout);
    setToastMessageDetails({ message: message, type: type });
    window.toastTimeout = setTimeout(() => {
      setToastMessageDetails(null);
    }, 10000);
  };
  return {
    setToastMessageDetails,
    toastMessageDetails,
    triggerToast,
    ToastContext,
  };
};
export default ToastMessageLogic;
