import axios from "axios";
import { get422errorMessage } from "../helpers";
import store from "../store";
import { setApiErrorMessage } from "../store/modules/apiErrorHandler";

// base url
// eslint-disable-next-line no-undef
let baseUrl = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const authHttpClient = axios.create({
  baseURL: baseUrl,
});

// setting authorization header
const httpRequest = (config) => {
  //config.headers["X-platform"] = "web";
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error) => {
  if (error?.response) {
    if (error?.response?.status) {
      if (error?.response?.status === 403)
        store.dispatch(setApiErrorMessage(error?.response?.data?.detail));

      if (error?.response?.status === 400 || error?.response?.status === 401) {
        store.dispatch(setApiErrorMessage(error?.response?.data?.detail));
      }

      if (error?.response?.status === 422) {
        const errors = error?.response?.data?.errors;
        if (Object.keys(errors)?.length > 0) {
          const errorMessage = get422errorMessage(errors);
          store.dispatch(setApiErrorMessage(errorMessage));
        } else {
          store.dispatch(setApiErrorMessage(error?.response?.data?.detail));
        }
      }

      if (error?.response?.status === 500)
        store.dispatch(
          setApiErrorMessage({
            message: "Something went wrong, please try again",
          }),
        );

      if (error?.response?.status === 503)
        store.dispatch(setApiErrorMessage("Service unavailable"));

      if (error?.response?.status === 404)
        store.dispatch(setApiErrorMessage("This resource was not found"));
    }
  }
  if (!navigator.onLine) {
    // Do something
    store.dispatch(
      setApiErrorMessage(
        "No internet connection, please check your internet connectivity",
      ),
    );
  }
  return Promise.reject(error);
};

/** Adding the request interceptors */
authHttpClient.interceptors.request.use(httpRequest);

/** Adding the response interceptors */
authHttpClient.interceptors.response.use(onFulfilled, onRejected);

export default authHttpClient;
