import { useContext, useState } from "react";
import { loginUser } from "endpoints/auth";
import { useMutation } from "react-query";
import { RequestLoader } from "context";
import { getRandomIntInclusive, getSlugName } from "helpers";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useLogin = () => {
  const {
    brand_name,
    description,
    business: { business_phone_code },
  } = useSelector((state) => state.businessDetailsreducer.businessDetails);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState(business_phone_code);
  const { isLoading, mutate: login } = useMutation((data) => loginUser(data));
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();

  const handleLogin = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    login(
      {
        phone_number: `${phoneNumber}`,
        phone_code: phoneCode,
        business_slug: getSlugName(),
      },
      {
        onSuccess: (response) => {
          navigate({
            pathname: "/login/otp",
            search: createSearchParams({
              session_id: response?.data?.data?.id,
              access_number: phoneNumber,
            }).toString(),
          });
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    phoneNumber,
    isLoading,
    brand_name,
    description,
    setPhoneNumber,
    handleLogin,
    setPhoneCode,
  };
};
export default useLogin;
